<template>
  <div v-show="stylesLoaded">
    <link rel="stylesheet" href="/HTML/css/global/plugins/icon-font.css" @load="onStyleLoaded">
    <link rel="stylesheet" href="/HTML/css/style.css" @load="onStyleLoaded">
    <header id="home">

    <!-- navbar -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
            <a class="navbar-brand" href="#">
                <!-- <h3 class="gradient-mask">Start.ly</h3> -->
                <img src="/img/logo17kb.png" alt="Start.ly Logo" style="height: 32px; margin-right: 10px; vertical-align: middle;">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#site-nav" aria-controls="site-nav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

            <div class="navbar-collapse" id="site-nav">
                <ul class="navbar-nav text-sm-left ml-auto">
                    <template v-if="isAuthenticated">
                        <li class="nav-item text-center pr-3">
                            <el-button @click="handleEnterSystem">进入系统</el-button>
                        </li>
                        <li class="nav-item text-center">
                            <el-avatar 
                                :size="32"
                                :src="userInfo.avatar || '/img/avatar/default.png'"
                                style="margin-right: 10px;"
                            />
                        </li>
                    </template>
                    <template v-else>
                        <li class="nav-item text-center">
                            <a href="#signup" class="btn align-middle btn-outline-primary my-2 my-lg-0">登录</a>
                        </li>
                        <li class="nav-item text-center">
                            <a href="#signup" class="btn align-middle btn-primary my-2 my-lg-0" 
                            @click.prevent="handleFreeTrial">申请试用</a>
                        </li>
                    </template>
                </ul>

            </div>
        </div>
    </nav>
    <!-- // end navbar -->


    <!-- hero -->
    <section class="jumbotron-two">

        <div class="container">

            <div class="row align-items-center">
                <div class="col-12 col-md-5">

                    <h1 class="display-5">数界探索 · 新一代税务审计AI </h1>
                    <p class="text-muted mb-3"> 革新税审体验，提升审计效率。数界探索税审AI 融合最新人工智能技术，
                        为您带来前所未有的精准审计体验。快速发现疑点，智能分析风险，
                        让每一次税务审计都更专业、更可靠。智启税审新未来1。</p>
                    <p>
                        <a href="#" class="btn btn-xl btn-primary" @click.prevent="handleFreeTrial">立即试用</a>
                    </p>

                </div>
                <div class="col-12 col-md-7 my-3 my-md-lg">

                    <div class="youtube cast-shadow" data-video-id="rm5sdAYCqqc" data-params="modestbranding=1&amp;showinfo=0&amp;controls=1&amp;vq=hd720">
                        <img src="/HTML/images/9401742917856_.pic.jpg" alt="image" class="img-fluid">
                        <!-- <div class="play"><span class="pe-7s-play pe-3x"></span></div> -->
                    </div>
                </div>
            </div>

        </div>

    </section>
    <!-- // end hero -->


    <div class="bg-shape"></div>
    <div class="bg-circle"></div>
    <div class="bg-circle-two"></div>
    </header>

  <!-- // end #services.section -->
  <div class="section bg-light pt-lg">
  <div class="container">

      <div class="row">
          <div class="col-md-6 col-lg-4">
              <div class="media mb-5">
                  <div class="media-icon d-flex mr-3"> <i class="pe-7s-server pe-3x"></i> </div>
                  <div class="media-body">
                      <h5 class="mt-0">智能数据整合</h5>
                      实时分析多源数据，自动整合关键信息至审计底稿，确保信息全面性和实时更新，让数据处理更高效。
                  </div>
              </div>
          </div>
          <div class="col-md-6 col-lg-4">
              <div class="media mb-5">
                  <div class="media-icon d-flex mr-3"> <i class="pe-7s-check pe-3x"></i> </div>
                  <div class="media-body">
                      <h5 class="mt-0">智能错误诊断</h5>
                      内置智能检测机制，快速识别数据异常，提供专业修正建议，确保底稿数据准确无误。
                  </div>
              </div>
          </div>
          <div class="col-md-6 col-lg-4">
              <div class="media mb-5">
                  <div class="media-icon d-flex mr-3"> <i class="pe-7s-rocket pe-3x"></i> </div>
                  <div class="media-body">
                      <h5 class="mt-0">效率倍增</h5>
                      自动化处理复杂数据，显著提升工作效率，让审计师专注于更有价值的分析与决策。
                  </div>
              </div>
          </div>
          <div class="col-md-6 col-lg-4">
              <div class="media mb-5">
                  <div class="media-icon d-flex mr-3"> <i class="pe-7s-monitor pe-3x"></i> </div>
                  <div class="media-body">
                      <h5 class="mt-0">简洁操作界面</h5>
                      直观易用的界面设计，零门槛上手操作，让非专业人士也能轻松掌握使用方法。
                  </div>
              </div>
          </div>
          <div class="col-md-6 col-lg-4">
              <div class="media mb-5">
                  <div class="media-icon d-flex mr-3"> <i class="pe-7s-settings pe-3x"></i> </div>
                  <div class="media-body">
                      <h5 class="mt-0">高度定制化</h5>
                      支持底稿格式和内容的个性化定制，灵活适应不同审计场景，满足多样化需求。
                  </div>
              </div>
          </div>
          <div class="col-md-6 col-lg-4">
              <div class="media mb-5">
                  <div class="media-icon d-flex mr-3"> <i class="pe-7s-shield pe-3x"></i> </div>
                  <div class="media-body">
                      <h5 class="mt-0">数据安全保障</h5>
                      采用先进加密技术，严格保护数据安全，确保审计信息的保密性和完整性。
                  </div>
              </div>
          </div>
          <!-- // end .col -->
      </div>
  </div>
  </div>

  <!-- Features -->
  <div class="section" id="features">

  <div class="container">
      <div class="row align-items-center">
          <div class="col-sm-8">
              <div class="browser-window limit-height my-5 mr-0 mr-sm-5">
                  <div class="top-bar">
                      <div class="circles">
                          <div class="circle circle-red"></div>
                          <div class="circle circle-yellow"></div>
                          <div class="circle circle-blue"></div>
                      </div>
                  </div>
                  <div class="content">
                      <img src="/HTML/images/dashboardmiddle.jpeg" alt="image">
                  </div>
              </div>
          </div>
          <div class="col-sm-4">
              <div class="media">
                  <div class="media-body">
                      <div class="media-icon mb-3"> <i class="pe-7s-like2 pe-3x"></i> </div>
                      <h3 class="mt-0">AI 智能税务审计</h3>
                      <p> 采用尖端人工智能技术，自动提取和分析多源数据，智能生成审计底稿。通过深度学习算法，实现精准的数据处理和风险识别，让审计工作更高效、更专业。</p>
                  </div>
              </div>
          </div>

      </div>



      <div class="row align-items-center mt-5">

          <div class="col-sm-4">
              <div class="media">
                  <div class="media-body">
                      <div class="media-icon mb-3"> <i class="pe-7s-graph1 pe-3x"></i> </div>
                      <h3 class="mt-0">智能数据可视化</h3>
                      <p> 自动生成专业的数据分析图表，直观展示审计结果和风险点。多样化的图表类型和交互式界面，让数据分析更清晰，决策更有依据。</p>
                  </div>
              </div>
          </div>


          <div class="col-sm-8">
              <img src="/HTML/images/screen.jpeg" alt="image" class="img-fluid cast-shadow my-5">
          </div>


      </div>
      <div class="row align-items-center">
          <div class="col-sm-8">
              <div class="browser-window limit-height my-5 mr-0 mr-sm-5">
                  <div class="top-bar">
                      <div class="circles">
                          <div class="circle circle-red"></div>
                          <div class="circle circle-yellow"></div>
                          <div class="circle circle-blue"></div>
                      </div>
                  </div>
                  <div class="content">
                      <img src="/HTML/images/9411742918305_.pic.jpg" alt="image">
                  </div>
              </div>
          </div>
          <div class="col-sm-4">
              <div class="media">
                  <div class="media-body">
                      <div class="media-icon mb-3"> <i class="pe-7s-like2 pe-3x"></i> </div>
                      <h3 class="mt-0">AI税法实时更新</h3>
                      <p> 采用先进AI技术打造的智能税法助手，实现精准的税法搜索与解读功能。系统整合海量真实案例和最新政策，通过智能化交互为您提供专业的税务指导。配备完整的税法资源库，涵盖各类税务场景，让复杂的税务问题变得简单易懂，助您轻松应对各种税务挑战。。</p>
                  </div>
              </div>
          </div>

      </div>
  </div>

    <div class="section" id="intro">
      <div class="container">

          <div class="client-logos text-center">
              <p class="text-muted">合作伙伴</p>
              <div class="d-flex flex-wrap justify-content-center">
                <img src="/HTML/images/client_logo_1.png" alt="client logo" class="partner-logo" />
                <img src="/HTML/images/client_logo_2.png" alt="client logo" class="partner-logo" />
                <img src="/HTML/images/client_logo_3.png" alt="client logo" class="partner-logo" />
                <img src="/HTML/images/client_logo_4.png" alt="client logo" class="partner-logo" />
                <img src="/HTML/images/client_logo_5.png" alt="client logo" class="partner-logo" />
                <img src="/HTML/images/client_logo_6.png" alt="client logo" class="partner-logo" />
                <img src="/HTML/images/client_logo_7.png" alt="client logo" class="partner-logo" />
                <img src="/HTML/images/client_logo_8.png" alt="client logo" class="partner-logo" />
                <img src="/HTML/images/client_logo_9.png" alt="client logo" class="partner-logo" />
                <img src="/HTML/images/client_logo_10.png" alt="client logo" class="partner-logo" />
              </div>
          </div>
          <!-- // end .client-logos -->

      </div>
      <!-- // end .container -->
    </div>

<!-- features -->

    <!-- 产品 -->
    <div class="section bg-light py-lg">
      <div class="container">

          <div class="row">
              <div class="col-md-6 col-lg-4">
                  <div class="media">

                      <!-- // end .di -->
                      <div class="media-body text-center">
                          <div class="color-icon mb-3"> <i class="pe-7s-medal pe-3x"></i> </div>
                          <h5 class="mt-0">税务审计</h5>
                          <p> 采用尖端人工智能技术，自动提取和分析多源数据，智能生成审计底稿。通过深度学习算法，实现精准的数据处理和风险识别，让审计工作更高效、更专业。</p>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-lg-4">
                  <div class="media">
                      <!-- // end .di -->
                      <div class="media-body text-center">
                          <div class="color-icon mb-3"> <i class="pe-7s-diamond pe-3x"></i> </div>
                          <h5 class="mt-0">税务咨询</h5>
                          <p> 提供专业的税务咨询服务，帮助企业解决复杂的税务问题。通过智能化的税务咨询系统，提供精准的税务解决方案，确保企业税务合规和税务优化。</p>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-lg-4">
                  <div class="media">
                      <!-- // end .di -->
                      <div class="media-body text-center">
                          <div class="color-icon mb-3"> <i class="pe-7s-cloud-upload pe-3x"></i> </div>
                          <h5 class="mt-0">税务合规</h5>
                          提供全面的税务合规解决方案，确保企业遵守相关法律法规，避免潜在的法律风险。通过智能化的税务合规检查，帮助企业快速识别和解决合规问题，确保税务合规的准确性和及时性。                      </div>
                  </div>
              </div>


          </div>
      </div>
    </div>
  </div>
<!-- // end features -->






    <!-- Pricing -->
<!--     <div class="section bg-light py-lg" id="pricing">
      <div class="container">

          <div class="section-title text-center mt-0 mb-5">
              <h3>选择你的使用规模</h3>
              <p> 选择最适合您的版本，享受数界探索AI带来的高效和便捷。</p>
          </div>

          <div class="row">
              <div class="col-lg-4">
                  <div class="card pricing">
                      <div class="card-body">
                          <small class="text-muted">基础版</small>
                          <h5 class="card-title">￥3800/年</h5>
                          <p class="card-text">
                              <ul class="list-unstyled">
                                  <li>10个项目</li>
                                  <li>10个用户</li>
                                  <li>每日20条对话额度</li>
                                  <li>数据分析与报告</li>
                              </ul>
                          </p>
                          <a href="#" class="btn btn-xl btn-outline-primary" @click.prevent="handleFreeTrial">选择</a>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4">
                  <div class="card pricing">
                      <div class="card-body">
                          <small class="text-muted">专业版</small>
                          <h5 class="card-title">￥6800/年</h5>
                          <p class="card-text">
                              <ul class="list-unstyled">
                                  <li>20个项目</li>
                                  <li>30个用户</li>
                                  <li>无限制AI对话</li>
                                  <li>快速支持</li>
                              </ul>
                          </p>
                          <a href="#" class="btn btn-xl btn-outline-primary" @click.prevent="handleFreeTrial">选择</a>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4">
                  <div class="card pricing">
                      <div class="card-body">
                          <small class="text-muted">企业版</small>
                          <h5 class="card-title">￥12800/年</h5>
                          <p class="card-text">
                              <ul class="list-unstyled">
                                  <li>50个用户</li>
                                  <li>100个项目</li>
                                  <li>无限制AI对话</li>
                                  <li>优先支持</li>
                              </ul>
                          </p>
                          <a href="#" class="btn btn-xl btn-primary" @click.prevent="handleFreeTrial">选择</a>
                      </div>
                  </div>
              </div>
          </div>

      </div>
    </div> -->

<!-- // end Pricing -->

  <!-- Signup -->
  <div class="section" id="signup">
      <div class="container">
          <div class="section-title text-center">
              <h3>登录</h3>
              <!-- <p>立即注册，免费试用仅需1分钟</p> -->
          </div>
          <div class="row justify-content-md-center">
              <div class="col col-md-5">
                <el-form
                  ref="loginForm"
                  :model="loginForm"
                  :rules="loginRules"
                  @submit.native.prevent="login"
                >
                      <div class="form-group">
                          <input type="text" class="form-control" placeholder="账号" v-model="loginForm.username" required>
                      </div>
                      <div class="form-group">
                          <input type="password" class="form-control" placeholder="密码" v-model="loginForm.password" required>
                      </div>
                      <div class="form-group">
                          <button type="submit" class="btn btn-xl btn-block btn-primary" @click.native.prevent="login">登录</button>
                      </div>
                  </el-form>
              </div>
          </div>

      </div>
  </div>



  <div class="section bg-light mt-4" id="footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-4"> 
                <img src="/img/logo17kb.png" class="logo-dark w-50" alt="数界探索AI Logo" />
                <p class="mt-3 ml-1 text-muted">数界探索AI是一个面向财税的AI工具，致力于提升财税工作效率，帮助财务从业者快速成长。 </p>
                <!-- // end .lead -->
            </div>
            <!-- // end .col-sm-3 -->
            <div class="col-sm-2">
                <!-- <ul class="list-unstyled footer-links ml-1">
                    <li><a href="#portfolio">Portfolio</a></li>
                    <li><a href="#about">About us</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul> -->
            </div>
            <!-- // end .col-sm-3 -->
            <div class="col-sm-2">
                <!-- <ul class="list-unstyled footer-links ml-1">
                    <li><a href="#">Terms</a></li>
                    <li><a href="#about">Privacy</a></li>
                </ul> -->
            </div>
            <!-- // end .col-sm-3 -->
            <div class="col-sm-2">
                <ul class="list-unstyled footer-links ml-1">
                    <!-- <li><a href="#">Facebook</a></li>
                    <li><a href="#">Twitter</a></li>
                    <li><a href="#">Linkedin</a></li> -->
                </ul>
            </div>
            <!-- // end .col-sm-3 -->
            <div class="col-sm-2">
                <a href="#home" class="btn btn-sm btn-outline-primary ml-1">Go to Top</a>
            </div>
            <!-- // end .col-sm-3 -->
        </div>
        <div class="d-flex justify-content-center align-items-center w-100 text-center" style="height: 100%;">
          <span class="text-muted">厦门数界探索计算机科学研究院有限公司 </span>
          <a class="ml-1" href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2024071913号-1</a>
        </div>
    </div>
  <!-- // end .container -->
  </div>

  <el-dialog
    title="申请开通账号"
    v-model="dialogVisible"
    width="400px"
    center
  >
    <div class="qrcode-container">
      <p>请添加客服，申请开通账号</p>
      <img src="/img/kefuewm1.png" alt="客服二维码" class="qrcode-img">
    </div>
  </el-dialog>
</div>
</template>

<script>
import 'jquery'
import '@popperjs/core'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import axios from 'axios';
import md5 from 'js-md5';
import { searchFirms } from '@/api/firm/firm';
import _ from 'lodash'; // 确保已安装 lodash
import { mapGetters, mapActions } from 'vuex';
import { info } from '@/api/system/tenant';
import { extractDomain } from '@/utils/util';   
import { getToken } from '@/utils/auth';

export default {
  name: 'Home1',
  data() {
    return {
      stylesLoaded: false,
      dialogVisible: false,
      loginForm: {
        firmId: "",
        username: "",
        password: "",
        grant_type: "password",
        tenantId: "",
      },
      loginRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能小于6个字符', trigger: 'blur' }
        ],
        firmId: [
          { required: true, message: '请选择事务所', trigger: 'change' }
        ]
      },
      loginError: '',
      isLoggedIn: false,
      userEmail: '',
    }
  },
  computed: {
    ...mapGetters(['firmWel','userInfo']),
    isAuthenticated() {
      return !!getToken();
    }
  },
  async created() {
    this.getTenant();
  },
  methods: {
    onStyleLoaded() {
      this.stylesLoaded = true
    },
    handleFreeTrial() {
      this.dialogVisible = true
    },
    getTenant() {
      let domain = extractDomain(window.location.href);
      // 临时指定域名，方便测试
        //   domain = 'https://firmsjtsyjy.numseek.com/portal/home'
        if(domain.includes('localhost')){
           //domain = 'firmsjtsyjy.numseek.com' 
           //domain = 'firmsz.numseek.com'
           domain = 'firmsjtest.numseek.com'
           //domain = 'firmxmhx.numseek.com'  
           //domain = 'firmsjedu.numseek.com'
          
        }
      info(domain).then(res => {
        const data = res.data;
        if (data.success && data.data.tenantId) {
          this.tenantMode = false;
          this.loginForm.tenantId = data.data.tenantId;
          this.$store.commit('SET_TENANT_INFO', data.data);
          this.$store.commit('SET_TENANT_TYPE', 'firm');
          // 如果需要更新背景图片，可以取消下面这行的注释
          // document.body.style.backgroundImage = `url(${data.data.backgroundUrl})`;
        } else {
          this.$confirm('当前域名未开通账号，是否联系管理员开通?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
          }).then(() => {
            // 用户点击确定后的操作
            this.handleFreeTrial(); // 打开二维码对话框
          }).catch(() => {
            // 用户点击取消的操作
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
        }
      });
    },
    handleEnterSystem() {
      // 直接使用 window.location.href 进行跳转
      window.location.href = this.firmWel.path;
    },
    login() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '登录中,请稍后',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          this.$store
            .dispatch('LoginByUsername', this.loginForm)
            .then(() => {
              loading.close();
              this.isLoggedIn = true;
              this.userEmail = this.loginForm.username;
              // 清空表单
              this.loginForm = { firmId: "", username: "", password: "" };
              // 跳转到 firmIndex 路由
              this.$store.commit('SET_PORTAL_URL', window.location.href);
            //   this.$router.push(this.firmWel.path);
              window.location.href = this.firmWel.path;
            })
            .catch(err => {
              console.error('登录失败:', err);
              loading.close();
              this.loginError = "登录失败，请检查您的用户名和密码。";
            });
        }
      });
    }
  }
}
</script>

<style>
.partner-logo {
  max-width: 100px; /* 根据需要调整大小 */
  margin: 10px;
}

.qrcode-container {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-img {
  width: auto;
  height: 300px;
  margin-top: 15px;
  object-fit: contain;
}
</style>
